import { useEffect } from 'react'
import useScrollStore from '@/store/scroll'

function useScrollListener(internalLenis, callback, deps = []) {
  const { lenis: defaultLenis } = useScrollStore()
  const lenis = internalLenis || defaultLenis

  useEffect(() => {
    if (!lenis) return
    lenis.on('scroll', callback)

    return () => { // eslint-disable-line consistent-return
      lenis.off('scroll', callback)
    }
  }, [lenis, callback, [...deps]])
}

export default useScrollListener
