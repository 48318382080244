import { Component } from 'react'
import injectSheet from 'react-jss'
import HTMLParser from 'react-html-parser'
import { TimelineMax, TweenMax, Power3 } from 'gsap'
import style from './style'

class Modal extends Component {
  /*------------------------------
  Component Will Mount
  ------------------------------*/
  UNSAFE_componentWillMount() {
    this.handleClickClose = this.handleClickClose.bind(this)
  }

  /*------------------------------
  Component Did Mount
  ------------------------------*/
  componentDidMount() {
    const { classes } = this.props
    this.$ = {
      root: this.node,
      image: this.node.getElementsByClassName(classes.image),
      background: this.node.getElementsByClassName(classes.background),
      text: this.node.getElementsByClassName(classes.text),
      btnClose: this.node.getElementsByClassName(classes.btnClose),
    }
    this.setTimeline()
  }

  /*------------------------------
  Component Did Update
  ------------------------------*/
  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      const { root } = this.$

      if (this.props.open) {
        TweenMax.set(root, { display: 'block' })
        this.timeline.play()
      } else {
        this.timeline.pause().timeScale(1.5).reverse()
      }
    }
  }

  /*------------------------------
  Handle Click Close
  ------------------------------*/
  handleClickClose() {
    this.timeline.pause().timeScale(1.5).reverse()
  }

  /*------------------------------
  Set Timeline
  ------------------------------*/
  setTimeline() {
    const { background, image, root, text, btnClose } = this.$
    this.timeline = new TimelineMax({
      paused: true,
      onReverseComplete: () => {
        TweenMax.set(root, { display: 'none' })
        this.props.setOpen(false)
        this.props.setImage(null)
        this.props.setText(null)
      },
    })
    this.timeline
      .fromTo(image, 1, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power3.easeInOut }, 0)
      .fromTo(background, 1, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power3.easeInOut }, 0)
      .fromTo(text, 0.5, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power3.easeInOut }, 0.7)
      .fromTo(btnClose, 0.6, { scale: 0 }, { scale: 1, ease: Power3.easeInOut }, 1)
    TweenMax.set(root, { display: 'none' })
  }

  /*------------------------------
  Render
  ------------------------------*/
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root} ref={(node) => { this.node = node }}>
        <div className={classes.container}>
          <div className={classes.image}><img src={this.props.image !== false ? this.props.image : ''} alt={(this.props.text !== null) ? this.props.text.title : ''} /></div>
          <div className={classes.text}>
            <article>
              <h2>{this.props.text !== null ? HTMLParser(this.props.text.title) : ''}</h2>
              <p>{this.props.text !== null ? this.props.text.article : ''}</p>
            </article>
            <div className={classes.btnClose}>
              <button
                onClick={this.handleClickClose}
                aria-label="close"
              >
                <svg>
                  <use xlinkHref="#ico-close" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className={classes.background} />
      </div>
    )
  }
}

export default injectSheet(style)(Modal)
