import * as types from './types'

export function setReady(bool) {
  return {
    type: types.LOADING_SET_READY,
    setReady: bool,
  }
}

export function setFontReady() {
  return {
    type: types.LOADING_SET_FONT_READY,
    setFontReady: true,
  }
}

export function setIncrementalStatus(state) {
  return {
    type: types.LOADING_SET_INC_STATUS,
    setIncrementalStatus: state,
  }
}
export function setFirstLoad(bool) {
  return {
    type: types.LOADING_FIRST_LOAD,
    setStatus: bool,
  }
}
export function setCookie(bool) {
  return {
    type: types.LOADING_SET_COOKIE,
    setCookie: bool,
  }
}
export function setRealLink(bool) {
  return {
    type: types.LOADING_SET_REAL_LINK,
    setRealLink: bool,
  }
}
