import { useState, useRef, useEffect, useCallback } from 'react'
import injectSheet from 'react-jss'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import clsx from 'clsx'
import DelayLink from '@/components/DelayLink'
import { setOpen, setCompact } from '@/actions/nav'
import { setHover } from '@/actions/pointer'
import Logo from '@/components/Logo'
import FormSearch from '@/components/FormSearch'
import LangNav from '@/components/__new/LangNav'
import BtnBurger from '@/components/BtnBurger'
import { theme } from '@/styles/style'
import style from './style'

function Header({
  classes,
}) {
  const $root = useRef()
  const [background, setBackground] = useState(false)

  const { strings, locales, navOpen, mediaQueryKey, currentPage, negative } = useSelector((state) => ({
    locales: state.locale.languages,
    strings: state.language.string,
    navOpen: state.nav.open,
    mediaQueryKey: state.mediaQuery.key,
    currentPage: state.page.current,
    layout: state.page.layout,
    negative: state.page.negative,
    modalText: state.modal.text,
    modalImage: state.modal.image,
    modalOpen: state.modal.open,
  }), shallowEqual)

  const dispatch = useDispatch()
  const setNavOpen = useCallback((bool) => dispatch(setOpen(bool)), [dispatch])
  const setNavCompact = useCallback((bool) => dispatch(setCompact(bool)), [dispatch])
  const setPointerHover = useCallback((bool) => dispatch(setHover(bool)), [dispatch])

  const update = () => {
    if (!negative && window.pageYOffset > 250 && !background) setBackground(true)
    if (!negative && window.pageYOffset < 250 && background) setBackground(false)
  }

  useEffect(() => {
    setNavCompact(background)
  }, [background])

  useEffect(() => {
    function raf() {
      update()
      requestAnimationFrame(raf)
    }

    raf()
  }, [negative, background])

  const backLink = () => {
    if (mediaQueryKey <= 1 && currentPage !== 'home') {
      return (
        <DelayLink to="" className={classes.backLink} back="true">
          <button
            className={`${classes.button} btnEye`}
            aria-label="back"
          >
            <svg>
              <use xlinkHref="#ico-download" />
            </svg>
          </button>
        </DelayLink>
      )
    }

    return null
  }

  const navLanguages = () => {
    return (
      <LangNav
        data={locales}
        hide={navOpen}
      />
    )
  }

  const formSearch = () => {
    if (mediaQueryKey > 1) {
      return (
        <FormSearch
          strings={strings}
          className={classes.inputSearch}
        />
      )
    }

    const search_link = strings.search_link !== undefined ? strings.search_link : ''

    return (
      <DelayLink to={search_link} className={classes.btnSearchLink}>
        <button
          type="button"
          className={classes.searchLink}
          aria-label="search"
        >
          <svg>
            <use xlinkHref="#ico-search" />
          </svg>
        </button>
      </DelayLink>
    )
  }

  return (
    <header
      className={clsx({
        [classes.root]: true,
        [classes.negative]: background || negative,
        [classes.navIsOpen]: navOpen,
      })}
      ref={$root}
    >
      <div
        className={clsx({
          [classes.wrapper]: true,
        })}
      >
        <div className={`${classes.logo} main-logo`}>
          <Logo
            show={true}
            navOpen={navOpen}
            theme={theme}
          />
        </div>
        {backLink()}
        {mediaQueryKey > 1 && navLanguages()}
        {formSearch()}
        <div className={`${classes.btnNav} main-btnnav`}>
          <BtnBurger
            show={true}
            navOpen={navOpen}
            label={strings.menu}
            labelClose={strings.generic_close}
            handleClick={setNavOpen}
            setHover={setPointerHover}
            open={navOpen}
            mediaQueryKey={mediaQueryKey}
            theme={theme}
          />
        </div>
      </div>
    </header>
  )
}

export default injectSheet(style)(Header)
