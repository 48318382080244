import { lazy } from 'react'
import { internazionalizePath, getPathFromCpt } from '@/utils/path'
import { theme } from '@/styles/style'

const Home = lazy(() => import(/* webpackChunkName: "home" */'@/pages/Home'))
const About = lazy(() => import(/* webpackChunkName: "about" */'@/pages/About'))
const History = lazy(() => import(/* webpackChunkName: "history" */'@/pages/History'))
const Videos = lazy(() => import(/* webpackChunkName: "videos" */'@/pages/Videos'))
const Portfolio = lazy(() => import(/* webpackChunkName: "portfolio" */'@/pages/Portfolio'))
const Product = lazy(() => import(/* webpackChunkName: "product" */'@/pages/Product'))
const Search = lazy(() => import(/* webpackChunkName: "search" */'@/pages/Search'))
const Contact = lazy(() => import(/* webpackChunkName: "contact" */'@/pages/Contact'))
// const Collection = lazy(() => import(/* webpackChunkName: "collection" */'@/pages/Collection'))
// const Collections = lazy(() => import(/* webpackChunkName: "collections" */'@/pages/Collections'))
const Project = lazy(() => import(/* webpackChunkName: "project" */'@/pages/Project'))
const Projects = lazy(() => import(/* webpackChunkName: "projects" */'@/pages/Projects'))
const Material = lazy(() => import(/* webpackChunkName: "material" */'@/pages/Material'))
const Materials = lazy(() => import(/* webpackChunkName: "materials" */'@/pages/Materials'))
const ArchiveNews = lazy(() => import(/* webpackChunkName: "archive_news" */'@/pages/ArchiveNews'))
const Post = lazy(() => import(/* webpackChunkName: "post" */'@/pages/Post'))
const NewArchiveProducts = lazy(() => import(/* webpackChunkName: "new_archive_products" */'@/pages/NewArchiveProducts'))
const DownloadIAM = lazy(() => import(/* webpackChunkName: "download_iam" */'@/pages/DownloadIAM'))
const Download = lazy(() => import(/* webpackChunkName: "download_file" */'@/pages/Download'))
const NewProduct = lazy(() => import(/* webpackChunkName: "new_product" */'@/pages/NewProduct'))
const Configurator = lazy(() => import(/* webpackChunkName: "configurator" */'@/pages/Configurator'))
const NotFound = lazy(() => import(/* webpackChunkName: "not_found" */'@/pages/NotFound'))

export default (routing, getImageUrl, getImageTitle) => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Home stub={routing.find((r) => r.cpt === 'homepage')?.slug} getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 1,
    path: internazionalizePath(
      getPathFromCpt(routing, 'about'),
    ),
    render: () => <About getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 2,
    path: internazionalizePath(
      getPathFromCpt(routing, 'page'),
    ),
    render: () => <About getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 4,
    path: internazionalizePath(
      getPathFromCpt(routing, 'story'),
    ),
    render: () => <History getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 5,
    path: internazionalizePath(
      getPathFromCpt(routing, 'videos'),
    ),
    render: () => <Videos getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 6,
    path: internazionalizePath(
      getPathFromCpt(routing, 'portfolio'),
    ),
    render: () => <Portfolio getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 7,
    path: internazionalizePath(
      getPathFromCpt(routing, 'product', 'stub'),
    ),
    render: () => <Product getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="product" />,
  },
  {
    key: 8,
    path: internazionalizePath(
      getPathFromCpt(routing, 'search'),
    ),
    render: () => <Search getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 9,
    path: internazionalizePath(
      getPathFromCpt(routing, 'contact'),
    ),
    render: () => <Contact getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  // {
  //   key: 11,
  //   path: internazionalizePath(
  //     getPathFromCpt(routing, 'collection', 'stub'),
  //   ),
  //   render: () => <Collection getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="collection" />,
  // },
  // {
  //   key: 12,
  //   path: internazionalizePath(
  //     getPathFromCpt(routing, 'collections'),
  //   ),
  //   render: () => <Collections getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  // },
  {
    key: 13,
    path: internazionalizePath(
      getPathFromCpt(routing, 'project', 'stub'),
    ),
    render: () => <Project getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="project" />,
  },
  {
    key: 14,
    path: internazionalizePath(
      getPathFromCpt(routing, 'projects'),
    ),
    render: () => <Projects getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 15,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cta_material_category', 'stub'),
    ),
    render: () => <Material getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="_cta_material_category" />,
  },
  {
    key: 16,
    path: internazionalizePath(
      getPathFromCpt(routing, 'materials'),
    ),
    render: () => <Materials getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="_cta_material_category" />,
  },
  {
    key: 17,
    path: internazionalizePath(
      getPathFromCpt(routing, 'category'),
    ),
    render: () => <ArchiveNews getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="post" />,
  },
  {
    key: 18,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_news'),
    ),
    render: () => <ArchiveNews getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="post" />,
  },
  {
    key: 19,
    path: internazionalizePath(
      getPathFromCpt(routing, 'post', 'year/:month/:stub'),
    ),
    render: () => <Post getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="post" />,
  },
  {
    key: 20,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cta_typology', 'stub'),
    ),
    render: () => <NewArchiveProducts getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="_cta_typology" />,
  },
  {
    key: 22,
    path: internazionalizePath(
      getPathFromCpt(routing, 'download_iam'),
    ),
    render: () => <DownloadIAM getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 23,
    path: internazionalizePath(
      getPathFromCpt(routing, 'download_file'),
    ),
    render: () => <Download getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} />,
  },
  {
    key: 24,
    path: internazionalizePath(
      getPathFromCpt(routing, 'family-detail', 'stub'),
    ),
    render: () => <NewProduct getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="family-detail" />,
  },
  {
    key: 25,
    path: internazionalizePath(
      getPathFromCpt(routing, 'configurator'),
    ),
    render: () => <Configurator stub={routing.find((r) => r.cpt === 'configurator')?.slug} getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="family-detail" />,
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
