/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import injectSheet from 'react-jss'
import HTMLParser from 'react-html-parser'
import NewsletterSubscription from '@/components/NewsletterSubscription'
import DelayLink from '@/components/DelayLink'
import { cleanOrigin } from '@/utils/path'
import style from './style'

function Footer({
  classes,
  data,
  strings,
  theme,
}) {
  let NewsletterDisclaim = strings.newsletter_disclaimer
  if (NewsletterDisclaim) {
    NewsletterDisclaim = NewsletterDisclaim.replace('#', strings.privacy_link)
  } else {
    NewsletterDisclaim = 'I accept the conditions on Privacy'
  }
  return (
    <footer className={`${classes.root} footer`}>
      <div className={`${classes.footerTop} ${classes.footerRow}`}>
        <div className={classes.footerLeft}>
          <svg className="first" xmlns="http://www.w3.org/2000/svg" width="76" height="15" preserveAspectRatio="xMidYMin slice">
            <use xlinkHref="#ico-logo" />
          </svg>
        </div>
        <div className={classes.footerCenter}>
          <div className="pretit">{(data.title !== undefined) ? HTMLParser(data.title) : ''}</div>
          <div className={classes.text}>
            {(data.text !== undefined) ? HTMLParser(data.text) : ''}
          </div>
        </div>
        <div className={classes.footerRight}>
          <div className={classes.subscribeNewsletter}>
            <NewsletterSubscription
              strings={strings}
              data={{
                title: 'Newsletter',
                privacy: NewsletterDisclaim,
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${classes.footerBottom} ${classes.footerRow}`}>
        <div className={classes.footerLeft}>
          <div className="pretit">
            <a href={strings.privacy_link} target="_blank">privacy policy</a>
            {strings.manage_cookies_label && (
            <button
              onClick={() => {
                const link = document.querySelector('.iubenda-tp-btn.iubenda-cs-preferences-link')
                if (link) link.click()
              }}
              data-layer="open-iub-preferences"
            >
              {strings.manage_cookies_label}
            </button>
            )}
          </div>
        </div>
        <div className={classes.footerCenter}>
          <img src={`${theme.IMAGE_URL}adimember.jpg`} alt="Adi Member" />
          <DelayLink to={strings.footer_russ}><img src={`${theme.IMAGE_URL}ciamrus.jpg`} alt="Ciam Rus" /></DelayLink>
          <DelayLink to={cleanOrigin(strings.footer_east)}><img src={`${theme.IMAGE_URL}ciam_middle_east.jpg`} alt="Ciam Middle East" /></DelayLink>
        </div>
        <div className={classes.footerRight}>
          <img src={data.imgRight} alt="" />
        </div>
      </div>
      {/* <div className={classes.footerBottom1}>
        <div className={`${classes.text} ${classes.footerBottom1Left}`}>
          {HTMLParser(strings.footer_fesr)}
        </div>
        <div className={classes.footerBottom1Right}>
          <img src={`${theme.IMAGE_URL}por-fesr.png`} alt="Por fesr 2018" />
        </div>
      </div>
      <div className={classes.footerBottom2}>
        <div className={`${classes.text} ${classes.footerBottom1Left}`}>
          {HTMLParser(strings.footer_fesr2020)}
        </div>
        <div className={classes.footerBottom1Right}>
          <img src={`${theme.IMAGE_URL}por-fesr.png`} alt="Por fesr 2020" />
        </div>
      </div> */}
    </footer>
  )
}

export default injectSheet(style)(Footer)
