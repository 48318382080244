export const clamp = (min, input, max) => {
  return Math.max(min, Math.min(input, max))
}

export const mapRange = (in_min, in_max, input, out_min, out_max) => {
  return ((input - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
}

export const newMapRange = (value, rangeA, rangeB, limit) => {
  if (limit == null) {
    limit = false; // eslint-disable-line
  }
  const [fromLow, fromHigh] = Array.from(rangeA)
  const [toLow, toHigh] = Array.from(rangeB)
  const result = toLow + ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow)

  if (limit === true) {
    if (toLow < toHigh) {
      if (result < toLow) {
        return toLow
      }
      if (result > toHigh) {
        return toHigh
      }
    } else {
      if (result > toLow) {
        return toLow
      }
      if (result < toHigh) {
        return toHigh
      }
    }
  }

  return result
}

export const lerp = (a, b, n) => (1 - n) * a + n * b

export const range = (value, min, max) => Math.min(Math.max(value, min), max)

export const shuffle = (array) => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const chunk = (array, size) => {
  const chunked = []
  for (let i = 0; i < array.length; i += 1) {
    const last = chunked[chunked.length - 1]
    if (!last || last.length === size) {
      chunked.push([array[i]])
    } else {
      last.push(array[i])
    }
  }
  return chunked
}

export const nearValue = (val, breakpoint, threshold) => {
  return val > (breakpoint - threshold) && val < (breakpoint + threshold)
}

export const clippedValue = (lowerBound, upperBound, value) => Math.max(lowerBound, Math.min(value, upperBound))

export function pad(size, length = 3) {
  let s = String(size)
  while (s.length < length) { s = `0${s}` }
  return s
}

export function formatNumberToTwoDigits(number) {
  if (number >= 0 && number < 10) {
    return `0${number}`
  }
  return number.toString()
}
