/* eslint-disable consistent-return */
/* https://github.com/studio-freight/satus/tree/main/components/scrollbar */
import { useEffect, useRef, useState } from 'react'
import { useWindowSize, useMeasure } from 'react-use'
import injectSheet from 'react-jss'
import { clamp, mapRange } from '@/utils/math'
import useScrollListener from '@/hooks/useScrollListener'
import useScrollStore from '@/store/scroll'
import style from './style'

function Scrollbar({ lenis: internalLenis, classes }) {
  const thumb = useRef()
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const { lenis: defaultLenis } = useScrollStore()
  const lenis = internalLenis || defaultLenis
  const [innerMeasureRef, { height: innerHeight }] = useMeasure()
  const [thumbMeasureRef, { height: thumbStaticHeight }] = useMeasure()
  const thumbHeight = useRef(thumbStaticHeight)

  useScrollListener(lenis, ({ scroll, limit }) => {
    const progress = scroll / limit
    thumb.current.style.transform = `translate3d(0, ${progress * (innerHeight - thumbHeight.current)}px, 0)`
  })

  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (!clicked) return

    function onPointerMove(e) {
      e.preventDefault()

      const offset = (windowHeight - innerHeight) / 2
      const y = mapRange(
        0,
        windowHeight,
        e.clientY,
        -offset,
        innerHeight + offset,
      )

      const progress = clamp(0, y / innerHeight, 1)
      const newPos = lenis.limit * progress

      !lenis.isHorizontal // eslint-disable-line
        ? window.scrollTo(0, newPos)
        : window.scrollTo(newPos, 0)
    }

    function onPointerUp() {
      setClicked(false)
    }

    window.addEventListener('pointermove', onPointerMove, false)
    window.addEventListener('pointerup', onPointerUp, false)

    return () => {
      window.removeEventListener('pointermove', onPointerMove, false)
      window.removeEventListener('pointerup', onPointerUp, false)
    }
  }, [clicked, windowHeight, windowWidth, lenis])

  /**
   * handle scrollbar thumb resize
   */
  useEffect(() => {
    const handleThumbResize = () => {
      if (!lenis || !thumb.current) return
      const { height, scrollHeight } = lenis.dimensions
      thumbHeight.current = height * (height / scrollHeight)
      thumb.current.style.height = `${thumbHeight.current}px`
    }

    window.addEventListener('resize', handleThumbResize, 'false')

    handleThumbResize()

    return () => {
      window.removeEventListener('scroll', handleThumbResize)
    }
  }, [lenis])

  return (
    <div className={classes.scrollbar}>
      <div ref={innerMeasureRef} className={classes.inner}>
        <div
          className={classes.thumb}
          ref={(node) => {
            thumb.current = node
            thumbMeasureRef(node)
          }}
          onPointerDown={() => setClicked(true)}
        />
      </div>
    </div>
  )
}

export default injectSheet(style)(Scrollbar)
