/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Component } from 'react'
import injectSheet from 'react-jss'
import { TweenMax } from 'gsap'
import HTMLParser from 'react-html-parser'
import style from './style'

/**
 * NewsletterSubscription
 * @class NewsletterSubscription
 * @example
 * <NewsletterSubscription />
 */
class NewsletterSubscription extends Component {
  /*------------------------------
  Component Will Mount
  ------------------------------*/
  UNSAFE_componentWillMount() {
    this.onSubmit = this.onSubmit.bind(this)
  }

  /*------------------------------
  Component Did Mount
  ------------------------------*/
  componentDidMount() {
    const { classes } = this.props

    this.$ = {
      root: this.node,
      form: this.node.getElementsByClassName(classes.form),
      input: this.node.getElementsByClassName(classes.input),
      customRadio: this.node.getElementsByClassName(classes.customRadio),
      radio: this.node.getElementsByClassName(classes.radio),
      newUser: this.node.getElementsByClassName(classes.newUser),
      oldUser: this.node.getElementsByClassName(classes.oldUser),
    }

    this.events()
  }

  /*------------------------------
  OnSubmit
  ------------------------------*/
  onSubmit(e) {
    const input = this.$.input[0]
    const radio = this.$.radio[0]
    const customRadio = this.$.customRadio[0]
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (radio.checked === false) {
      customRadio.classList.add('error')
      setTimeout(() => {
        customRadio.classList.remove('error')
      }, 4000)
    }

    if (re.test(input.value) === false) {
      input.classList.add('error')
      setTimeout(() => {
        input.classList.remove('error')
      }, 4000)
    }

    if (radio.checked === true && re.test(input.value) === true) {
      this.sendData(input.value, this.props.strings.newsletter_link)
    }
    e.preventDefault()
  }

  /*------------------------------
  Send Data
  ------------------------------*/
  sendData(data, url) {
    const XHR = new XMLHttpRequest()
    const FD = new FormData()

    // eslint-disable-next-line no-restricted-syntax
    for (const n in data) {
      if (Object.prototype.hasOwnProperty.call(n, data)) {
        FD.append(n, data[n])
      }
    }

    XHR.addEventListener('load', (e) => {
      const response = JSON.parse(e.currentTarget.response)

      // Membro registrato
      if (response.message.obj.id !== undefined) {
        TweenMax.to(this.$.form[0], 0.5, {
          autoAlpha: 0,
        })
        TweenMax.to(this.$.newUser[0], 0.5, {
          display: 'block',
          autoAlpha: 0.5,
          delay: 0.55,
        })

        setTimeout(() => {
          TweenMax.to(this.$.form[0], 0.5, {
            autoAlpha: 1,
            delay: 0.55,
          })
          TweenMax.to(this.$.newUser[0], 0.5, {
            display: 'none',
            autoAlpha: 0,
          })
        }, 5000)
      } else if (response.message.obj.title !== undefined) {
        TweenMax.to(this.$.form[0], 0.5, {
          autoAlpha: 0,
        })
        TweenMax.to(this.$.oldUser[0], 0.5, {
          display: 'block',
          autoAlpha: 0.5,
          delay: 0.55,
        })

        setTimeout(() => {
          TweenMax.to(this.$.form[0], 0.5, {
            autoAlpha: 1,
            delay: 0.55,
          })
          TweenMax.to(this.$.oldUser[0], 0.5, {
            display: 'none',
            autoAlpha: 0,
          })
        }, 5000)
      }
    })

    XHR.open('POST', `${url}${data}`)
    XHR.send(FD)
  }

  /*------------------------------
  Events
  ------------------------------*/
  events() {
    this.$.form[0].addEventListener('submit', (event) => {
      this.onSubmit(event)
      event.preventDefault()
    })
  }

  /*------------------------------
  Render
  ------------------------------*/
  render() {
    const { classes, data, strings } = this.props
    return (
      <div className={classes.subscribeNewsletter} ref={(node) => { this.node = node }}>
        <div className="pretit">{(data.title !== undefined) ? HTMLParser(data.title) : ''}</div>
        <div className={classes.newUser}>{strings?.new_user}</div>
        <div className={classes.oldUser}>{strings?.old_user}</div>
        <form action="" method="post" className={classes.form}>
          <input
            className={classes.input}
            placeholder="E-mail"
            type="email"
          />
          <button
            type="submit"
            className={`${classes.submit} ${classes.button}`}
            aria-label="download"
          >
            <svg>
              <use xlinkHref="#ico-download" />
            </svg>
          </button>
          <div className={classes.customRadio}>
            <label>
              <input
                id="newsletterPrivacy"
                type="checkbox"
                value="privacy"
                className={classes.radio}
              />
              <span>
                {(data.privacy !== undefined) ? HTMLParser(data.privacy) : ''}
              </span>
            </label>
          </div>
        </form>
      </div>
    )
  }
}

export default injectSheet(style)(NewsletterSubscription)
