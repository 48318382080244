import { Component } from 'react'
import injectSheet from 'react-jss'
import { history } from '@/base/store'
import { cleanOrigin } from '@/utils/path'
import style from './style'

class FormSearch extends Component {
  /*------------------------------
  Component Did Mount
  ------------------------------*/
  componentDidMount() {
    const { classes } = this.props

    this.$ = {
      root: this.node,
      input: this.node.getElementsByClassName(classes.input),
    }
  }

  /*------------------------------
  Handle Click
  ------------------------------*/
  handleSubmit = (event) => {
    event.preventDefault()
    const { strings } = this.props
    const searchValue = this.$.input[0].value
    history.push(cleanOrigin(`${strings.search_link}?q=${searchValue}`))
  }

  /*------------------------------
  Render
  ------------------------------*/
  render() {
    const { classes, className, strings } = this.props

    return (
      <div className={`${classes.root} ${className}`} ref={(node) => { this.node = node }}>
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            placeholder={strings.search}
            className={classes.input}
            id="search-input-ciam"
          />
          <button
            type="submit"
            className={classes.submit}
            aria-label="search"
          >
            <svg>
              <use xlinkHref="#ico-search" />
            </svg>
          </button>
        </form>
      </div>
    )
  }
}

export default injectSheet(style)(FormSearch)
