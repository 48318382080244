import { mainCacheAxios as axios } from '@/base/axios'
import { API } from '@/constants'
import * as types from './types'

export const fetchStrings = () => {
  return async (dispatch, getState) => {
    if (window.strings) {
      dispatch({
        type: types.STRING_GET_ALL,
        data: window.strings,
      })
    } else {
      const response = await axios.get(`${API.STRINGS}?lang=${getState().locale.currentLanguage}`)
      dispatch({
        type: types.STRING_GET_ALL,
        data: response.data,
      })
    }
  }
}
