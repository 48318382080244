import * as types from '../actions/types'

const initialState = {
  status: true,
  incremental: 0,
  firstLoad: true,
  ready: false,
  cookie: false,
  realLink: false,
  isFontReady: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_SET_READY:
      state = {
        ...state,
        ready: action.setReady,
      }
      break
    case types.LOADING_SET_FONT_READY:
      state = {
        ...state,
        isFontReady: action.setFontReady,
      }
      break
    case types.LOADING_SET_INC_STATUS:
      state = {
        ...state,
        incremental: action.setIncrementalStatus,
      }
      break
    case types.LOADING_FIRST_LOAD:
      state = {
        ...state,
        firstLoad: action.setStatus,
      }
      break
    case types.LOADING_SET_COOKIE:
      state = {
        ...state,
        cookie: action.setCookie,
      }
      break
    case types.LOADING_SET_REAL_LINK:
      state = {
        ...state,
        realLink: action.setRealLink,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}

export default reducer
