import { memo, useRef, useState, useCallback, useEffect } from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'

import style from '../style'

function TextField({
  className,
  classes,
  disabled,
  error,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  touched,
  type,
  value,
  withLabel,
}) {
  const $input = useRef()
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const showPassword = useCallback(() => {
    setPasswordVisible(!isPasswordVisible)
  }, [isPasswordVisible])

  useEffect(() => {
    if (type.includes('password')) $input.current.setAttribute('type', isPasswordVisible ? 'text' : 'password')
  }, [isPasswordVisible])

  return (
    <div
      className={clsx({
        [classes.formControl]: true,
        [classes.formControlDisabled]: disabled,
        [classes.formError]: error && touched,
        [className]: className,
      })}
    >
      <label>
        {withLabel && (
          <span className={classes.label}>
            {placeholder}
            {' '}
            {required ? '*' : ''}
          </span>
        )}
        <input
          ref={$input}
          className={classes.input}
          type={type.includes('password') ? 'password' : type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value || ''}
          disabled={disabled}
          autoComplete={type === 'password' ? 'on' : 'off'}
        />
        {type.includes('password') && (
          <button
            type="button"
            className={clsx({
              [classes.showPassword]: true,
              [classes.passwordIsVisible]: isPasswordVisible,
            })}
            onClick={() => showPassword($input)}
            aria-label="show"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
              <path d="M50 81.82c-11.69 0-23.62-5.47-34.71-14.42C7.25 60.9 0 52.6 0 50s7.25-10.9 15.29-17.39C26.38 23.66 38.3 18.19 50 18.19s23.62 5.47 34.71 14.42C92.75 39.1 100 47.41 100 50s-7.25 10.9-15.29 17.39C73.62 76.34 61.7 81.81 50 81.81Zm0-4.55c10.48 0 21.5-5.05 31.85-13.42 3.89-3.14 7.45-6.58 10.24-9.78 1.15-1.31 2.1-2.51 2.75-3.45.36-.52.62-1 .62-.63s-.26-.11-.62-.63c-.65-.94-1.6-2.14-2.75-3.45-2.79-3.19-6.35-6.63-10.24-9.78C71.5 27.77 60.48 22.71 50 22.71s-21.5 5.05-31.85 13.42c-3.89 3.14-7.45 6.58-10.24 9.78-1.15 1.31-2.1 2.51-2.75 3.45-.36.52-.62 1-.62.63s.26.11.62.63c.65.94 1.6 2.14 2.75 3.45 2.79 3.19 6.35 6.63 10.24 9.78C28.5 72.21 39.52 77.27 50 77.27Zm0-9.09c-10.04 0-18.18-8.14-18.18-18.18S39.96 31.82 50 31.82 68.18 39.96 68.18 50 60.04 68.18 50 68.18Zm0-4.55c7.53 0 13.64-6.11 13.64-13.64S57.53 36.35 50 36.35s-13.64 6.11-13.64 13.64S42.47 63.63 50 63.63Z" />
              <path id="line" d="m83.45 19.35-64.1 64.1c-.68.68-1.8.68-2.48 0l-.32-.32c-.68-.68-.68-1.8 0-2.48l64.1-64.1c.68-.68 1.8-.68 2.48 0l.32.32c.68.68.68 1.8 0 2.48Z" />
            </svg>
          </button>
        )}
      </label>
      <div
        className={clsx({
          [classes.error]: true,
          [classes.visible]: error && touched,
        })}
      >
        {error}
      </div>
    </div>
  )
}

TextField.defaultProps = {
  withLabel: false,
}

export default injectSheet(style)(memo(TextField))
